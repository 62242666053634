<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Intervenants
            </div>
          </template>
          <v-card-text>
            <v-row>
              <v-col
                md="12"
                cols="12"
              >
              <h2>Avocats intervenants aux ateliers : </h2><br><br>
<table cellspacing="0" border="0" style="margin:auto">
	<colgroup width="202"></colgroup>
	<colgroup width="158"></colgroup>
	<colgroup width="133"></colgroup>
	<colgroup width="265"></colgroup>
	<tbody><tr>
		<td style="border-top: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" colspan="4" height="59" align="center" valign="middle" bgcolor="#1D6295" sdnum="1033;0;D-MMM"><font face="Arial" size="3" color="#FFFFFF">Jeudi 30 septembre : Anticipation</font></td>
		</tr>
	<tr v-for="(atl,index) in AVO_ATL_1" :key="index">
		<td class="text-center" style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">{{atl.name}} </font></b></td>
		<td class="text-center" style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">{{atl.type}}</font></td>
		<td class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">{{atl.telephone}}</font></td>
		<td class="text-center" style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><u><font color="#6B9F25"><a :href="'mailto:'+atl.email">{{atl.email}}</a></font></u></td>
	</tr>
	<tr>
		<td style="border-left: 2px solid #000000; border-right: 2px solid #000000" colspan="4" height="59" align="center" valign="middle" bgcolor="#1D6295" sdnum="1033;0;D-MMM"><font face="Arial" size="3" color="#FFFFFF">Vendredi 1 octobre : Négociation</font></td>
		</tr>
	<tr v-for="(atl,index1) in AVO_ATL_2" :key="index1">
		<td class="text-center" style="border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" height="48" align="left" valign="middle"><b><font face="Arial" color="#65757D">{{atl.name}}</font></b></td>
		<td class="text-center" style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign="middle"><font face="Arial" color="#65757D">{{atl.type}}</font></td>
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="middle" sdnum="1033;0;M/D/YYYY"><font face="Arial" color="#65757D">{{atl.telephone}} </font></td>
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="left" valign="middle"><font face="Arial" color="#65757D"><a :href="'mailto:'+atl.email">{{atl.email}}</a></font></td>
	</tr>
	<tr>
		<td height="16" align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman"><br></font></td>
	</tr>
	<tr>
		<td rowspan="3" height="48" align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br>
		</font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman"><br></font></td>
	</tr>
	<tr>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman"><br></font></td>
	</tr>
	<tr>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman" color="#000000"><br></font></td>
		<td align="left" valign="bottom"><font face="Times New Roman"><br></font></td>
	</tr>
</tbody></table>
                   <br><h2>Avocats des consultations :</h2><br>
<table cellspacing="0" border="0">
	<colgroup width="175"></colgroup>
	<colgroup width="107"></colgroup>
	<colgroup width="301"></colgroup>
	<colgroup width="440"></colgroup>
	<tbody><tr>
		</tr>
	<tr>
		<td height="20" align="left" valign="bottom"><br></td>
		<td align="left" valign="bottom"><br></td>
		<td align="left" valign="bottom"><font color="#4472C4"><br></font></td>
		<td align="left" valign="bottom"><font face="Tahoma"><br></font></td>
	</tr>
	<tr>
		<td  class="text-center" style="border-top: 1px solid #000000; border-left: 1px solid #000000" height="49" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Nom</font></b></td>
		<td  class="text-center" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Prénom</font></b></td>
		<td  class="text-center" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Mail </font></b></td>
		<td  class="text-center" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Domaine d'intervention </font></b></td>
	</tr>
	<tr v-for="(cons,j) in AVO_CONS" :key="j" >
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font size="3" color="#000000">{{cons.nom}}</font></td>
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">{{cons.prenom}}</font></td>
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a :href="'mailto:'+cons.mail">{{cons.mail}}</a></font></u></td>
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">{{cons.domaine}}</font></td>
	</tr>
</tbody></table>
<!--
                   <br><h2>Avocats par domaine d'intervention :</h2><br>
<table cellspacing="0" border="0">
	<colgroup width="432"></colgroup>
	<colgroup width="175"></colgroup>
	<colgroup width="107"></colgroup>
	<colgroup width="301"></colgroup>
	<tbody><tr>
		</tr>
	<tr>
		<td height="20" align="left" valign="bottom"><font color="#000000"><br></font></td>
		<td align="left" valign="bottom"><br></td>
		<td align="left" valign="bottom"><br></td>
		<td align="left" valign="bottom"><font color="#4472C4"><br></font></td>
	</tr>
	<tr>
		<td height="49" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Domaine d'intervention </font></b></td>
		<td style="border-top: 1px solid #000000; border-left: 1px solid #000000" align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Nom</font></b></td>
		<td align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Prénom</font></b></td>
		<td align="center" valign="middle" bgcolor="#2F5597"><b><font face="Tahoma" size="4" color="#FFFFFF">Mail </font></b></td>
	</tr>
	<tr v-for="(intr,k) in AVO_INTR" :key="k">
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="27" align="left" valign="bottom"><font face="Tahoma" size="3" color="#000000">{{intr.domaine}}</font></td>
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">{{intr.nom}}</font></td>
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom"><font size="3" color="#000000">{{intr.prenom}}</font></td>
		<td  class="text-center" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="left" valign="bottom" sdnum="1033;0;@"><u><font size="3" color="#000000"><a :href="'mailto:'+intr.mail">{{intr.mail}}</a></font></u></td>
	</tr>
</tbody></table>-->
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Intervenants',
	 data () {
      return {
        AVO_ATL_1: [
			{
				name: 'Sandrine CLEVENOT',
				type: 'Corpolegem',
				telephone: '06 60 50 24 17',
				email: 'sc@corpolegem.com'
			},
			{
				name: 'Stéphane BALLER',
				type: 'De Gaulle Fleurance & Associés ',
				telephone: '06 15 26 15 01 ',
				email: 'baller.stephane@avocat-conseil.fr '
			},
			{
				name: 'Alexandre BORDENAVE',
				type: 'BFL',
				telephone: '01 47 38 56 33',
				email: 'alexandre.bordenave@cms-fl.com'
			},
			{
				name: 'Vincent MAUREL',
				type: 'KPMG',
				telephone: '06 76 48 81 86',
				email: 'vmaurel@kpmgavocats.fr'
			},
			{
				name: 'Fabrice NAFTAlSKI',
				type: 'EY',
				telephone: '06.07.70.87.58',
				email: 'fabrice.naftalski@ey-avocats.com'
			},
			{
				name: 'Théophile FORNACCIARI',
				type: 'FHB FIDUCIE',
				telephone: '06 33 22 85 32',
				email: 'theophile.fornacciari@fhbfiducie.eu'
			},
			{
				name: 'Arnaud PEDRON',
				type: 'Cabinet Franklin',
				telephone: '06 74 02 93 40',
				email: 'apedron@franklin-paris.co'
			},
		],
		AVO_ATL_2: [
			{
				name: 'Sonia KOUTCHOUK',
				type: 'Avocat Koutchouck',
				telephone: '01 49 10 94 50',
				email: 'sk@avocat-koutchouk.com'
			},
			{
				name: 'Claude DUVERNOY',
				type: 'Droitfil',
				telephone: '06 72 84 92 43',
				email: 'claudeduvernoy@droitfil.fr'
			},
			{
				name: 'Mariannick CANEVET',
				type: 'CANEVET',
				telephone: '01 46 64 02 51',
				email: 'avocat-canevet@orange.fr'
			},
			{
				name: 'Ludivine JOUHANNY',
				type: 'JLAvocat',
				telephone: '06 86 18 52 44',
				email: 'l.jouhanny@cabinetjlavocat.fr'
			},
			{
				name: 'Jean-Philippe MARIANI',
				type: 'Cabinet Mariani',
				telephone: '01 55 46 50 50',
				email: 'jpmariani@cabinet-m.fr'
			},
			{
				name: 'Sylvie VAQUIERI',
				type: 'Cabinet Fidal',
				telephone: '06 34 36 12 17',
				email: 'sylvie.vaquieri@fidal.com'
			},
			{
				name: 'Marie Lorraine HENRY',
				type: 'Cabinet Fidal',
				telephone: '06 71 45 65 52',
				email: 'marie-lorraine.henry@fidal.com'
			},
			{
				name: 'Elisabeth BONAN',
				type: 'Cabinet Fidal',
				telephone: '06 27 12 83 43',
				email: 'elisabeth.bonan@fidal.com'
			},
		],
		AVO_CONS: [
			{
				nom: 'ABCI',
				prenom: 'Kamilia',
				mail: 'kamilia.abci@gmail.com',
				domaine: 'Droit de la famille et droit pénal'
			},
			{ nom: 'ARIF', prenom: 'Linda',mail: 'linda.arif.avocat@gmail.com',domaine: 'Droit des assurances, droit de la famille et droit pénal'},
			{ nom: 'BOULAND', prenom: 'Guillaume',mail: 'gboulan@crtd-avocats.com',domaine: 'Droit du travail droit de la sécurité sociale'},
			{ nom: 'BRET', prenom: 'Hortense',mail: '',domaine: 'Droit des affaires/droit des sociétés'},
			{ nom: 'CANNEVET', prenom: 'Mariannick',mail: 'avocat-canevet@orange.fr',domaine: 'Contentieux commercial et droits des sociétés'},
			{ nom: 'CARENZI', prenom: 'Thomas',mail: 'thomas.carenzi@cms-fl.com',domaine: 'Droit public des affaires'},
			{ nom: 'CAUBEL', prenom: 'Sophie',mail: 'sc.avocat@cabinetcaubel.com',domaine: 'Droit social'},
			{ nom: 'DAL VECCHIO', prenom: 'Frédéric',mail: 'fredericdalvecchio@fdv-avocat.fr',domaine: 'Droit fiscal, droit des sociétés'},
			{ nom: 'DAOUDI', prenom: 'Nabil',mail: 'Nabil.daoudi@ey-avocats.com',domaine: 'Fiscalité des entreprises, fiscalité des particuliers'},
			{ nom: 'DE CASTELNEAU', prenom: 'Héloîse',mail: 'heloisedecastelnau@gmail.com',domaine: 'Contentieux commercial, droit des contrats'},
			{ nom: 'DE VARAX', prenom: 'Jean-Baptiste',mail: 'jean-baptiste.de-varax@fidal.com',domaine: 'Banque/financement assurances'},
			{ nom: 'DEBIN', prenom: 'Cécile',mail: 'cdebin@taj.fr',domaine: ''},
			{ nom: 'DELSART', prenom: 'Christophe',mail: 'delsart@delsart.eu',domaine: 'Droit social'},
			{ nom: 'DJIAN', prenom: 'Franck',mail: 'fd@fdavocats.com',domaine: 'Droit fiscal'},
			{ nom: 'DURIEZ', prenom: 'Corine',mail: 'duriez@everlex.fr',domaine: 'Droit du travail'},
			{ nom: 'HOEL', prenom: 'Elodie',mail: 'elodie.hoel@yahoo.com',domaine: 'Droit des nouvelles technologies / propriété intellectuelle'},
			{ nom: 'JOUHANNY', prenom: 'Ludivine',mail: 'L.jouhanny@cabinetjlavocat.fr',domaine: 'Droit des entreprises en difficulté'},
			{ nom: 'KALDOR', prenom: 'François',mail: 'francois.kaldor@orange.fr',domaine: 'Droit immobiler'},
			{ nom: 'LAUGERY', prenom: 'Pierre-Ann',mail: 'laugery109@gmail.com',domaine: 'Droit pénal'},
			{ nom: 'LECLERC', prenom: 'Yann',mail: 'yann.leclerc@cms-fl.com',domaine: 'Fiscalité des entreprises'},
			{ nom: 'MANGIN', prenom: 'Virginie',mail: 'valerie.mangin@orange.fr',domaine: 'Baux commerciaux, droit des société, droit des contrats'},
			{ nom: 'MANIÈRE', prenom: 'julien',mail: 'julien.maniere@fidal.com',domaine: 'Contentieux commercial droits des contrats médiation'},
			{ nom: 'MICHEL', prenom: 'Laura',mail: 'lauramichel@kpmgavocats.fr',domaine: 'Droit pénal, droit de la consommation et nouvelles technologies'},
			{ nom: 'MINCU', prenom: 'Daniela',mail: 'daniela.mincu@danielacineleanu.ro',domaine: 'Droit des affaires/droit des sociétés'},
			{ nom: 'NIAMBA', prenom: 'Laetitia',mail: 'laetitia.niamba@anetia.fr',domaine: 'Droit des affaires/droit des sociétés'},
			{ nom: 'PESTRE', prenom: 'Mayeul',mail: 'mpestre@kpmgavocats.fr',domaine: 'Droit des sociétés, Droit des baux commerciaux'},
			{ nom: 'QUINTARD', prenom: 'Clémentine',mail: 'clementine.quintard@cms-fl.com',domaine: 'Droit des entreprises en difficultés'},
			{ nom: 'RAINAUT', prenom: 'Pauline',mail: 'paulinerainaut.avocat@gmail.com',domaine: 'Droit pénal'},
			{ nom: 'RUSSO', prenom: 'Eric',mail: 'ericrusso@quinnemanuel.com',domaine: 'Droit pénal des affaires et droit pénal'},
			{ nom: 'SANTINI', prenom: 'Frédéric',mail: 'fsantini@crtd-avocats.com',domaine: 'Droit des assurances et droit de la construction'},
			{ nom: 'SCAGLIA', prenom: 'Jenna',mail: 'jenna.scaglia@avocats-mazars.com',domaine: 'Fiscalité des entreprsies / fiscalité des particuliers'},
			{ nom: 'TEADJIO DONGMO', prenom: 'ARMAND',mail: 'armandongmo@gmail.com',domaine: 'Droits des contrats, droit de la famille, droit pénal'},
			{ nom: 'VAHEDIAN', prenom: 'Mina',mail: 'mvahedian.avocat@gmail.com',domaine: 'Droit des sociéts, fiscalité des particuliers'},
		],
		AVO_INTR: [
			{
				domaine: 'test',
				nom: 'aa',
				prenom: 'bb',
				mail: 'bb@',
				
			},
		]
      }
    },
  }
</script>
